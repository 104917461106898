import {get, post, fileDown, fileUpload} from "@/utils/request.js";
import {sh} from "@dcloudio/vue-cli-plugin-uni/packages/webpack-preprocess-loader/preprocess/lib/regexrules";

/**
 * 呼吸
 * @returns {AxiosPromise}
 */
export function respire() {
    return get('/house/housing/respire');
}

/**
 * 栋列表
 */
export function buildingPageList(pageParams, isLoading) {
    return post("/house/housing/building/list", pageParams, isLoading);
}

/**
 * 栋详情
 */
export function buildingDetails(id) {
    return get(`/house/housing/building/${id}`, {}, true);
}

/**
 * 栋的选项-当前用户
 */
export function buildingCreate(data) {
    return post("/house/housing/building", data, true);
}

/**
 * 栋的选项-当前用户
 */
export function buildingUpdate(id, data) {
    return post(`/house/housing/building/${id}`, data, true);
}

/**
 * 栋的选项-当前用户
 */
export function usedRegions() {
    return get("/house/housing/building/usedRegions", null, true, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}

/**
 * 栋的选项-默认费用列表
 */
export function defaultFeeList() {
    return get("/house/housing/building/defaultFee", {}, true);
}

/**
 * 栋的选项-全部
 */
export function regionBuildingOption() {
    return get("/house/housing/building/regionOptions", {}, false, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}

/**
 * 栋的选项-全部
 */
export function regionOverOptions() {
    return get("/house/housing/building/regionOverOptions", {}, true, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}

/**
 * 栋的选项-当前用户
 */
export function suiteOption(buildingId) {
    return get(`/house/housing/suite/suiteOption/${buildingId}`);
}

/**
 * 户的选项-当前用户
 */
export function buildingSuiteOverOption() {
    return get("/house/housing/suite/buildingSuiteOverOption", undefined, false, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}

/**
 * 户的选项-当前用户
 */
export function buildingSuiteOption() {
    return get("/house/housing/suite/buildingSuiteOption", undefined, false, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT);
}

/**
 * 户的选项-当前用户
 */
export function buildingSuiteAvailableOption(buildingId) {
    return get(`/house/housing/suite/availableOption/${buildingId}`, undefined, false);
}

/**
 * 创建户
 */
export function createSuiteDefaultData(buildingId) {
    return get(`/house/housing/suite/defaultData/${buildingId}`, undefined, true)
}
/**
 * 创建户
 */
export function createSuite(data) {
    return post("/house/housing/suite", data, true)
}

/**
 * 创建户
 */
export function updateSuite(id, data) {
    return post(`/house/housing/suite/${id}`, data, true)
}

/**
 * 创建户
 */
export function findUpdateData(id, data) {
    return get(`/house/housing/suite/updateData/${id}`, data, true)
}

/**
 * 查询户所有数据
 */
export function suiteList(data, isLoading) {
    return post("/house/housing/suite/pageList", data, isLoading);
}

/**
 * 户详情
 */
export function suiteDetail(id) {
    return get(`/house/housing/suite/${id}`, undefined, true);
}

/**
 * 加入默认数据
 */
export function defaultInviteData(suiteId) {
    return get(`/house/housing/suite/join/${suiteId}`, undefined, true);
}

/**
 * 发起邀请
 */
export function inviteJoin(data) {
    return post("/house/housing/suite/initiateInvite", data)
}

/**
 * 发起邀请
 */
export function joinList(data, isLoading) {
    return post("/house/housing/join/list", data, isLoading)
}

/**
 * 发起邀请
 */
export function joinDetails(id) {
    return get(`/house/housing/join/${id}`, undefined, true)
}

/**
 * 查询入住数据
 */
export function findReserved(id) {
    return get(`/house/housing/join/findReserved/${id}`, undefined, true)
}


/**
 * 退租
 */
export function rentTermination(data) {
    return post("/house/housing/join/rentTermination", data, true)
}

/**
 * 退租
 */
export function findRentTermination(id) {
    return get(`/house/housing/join/findRentTermination/${id}`, undefined, true)
}

/**
 * 发起邀请
 */
export function agreeCheckIn(data) {
    return post('/house/housing/join/agreeCheckIn', data, true)
}


/**
 * 管理员列表
 */
export function adminList(data, isLoading) {
    return post("/house/housing/admin", data, isLoading);
}

/**
 * 管理员邀请
 */
export function adminInvite(data) {
    return post("/house/housing/admin/initiateInvite", data)
}
/**
 * 管理员邀请
 */
export function inviteConfirm(id) {
    return post(`/house/housing/admin/inviteConfirm/${id}`, {}, true)
}

/**
 * 管理员邀请取消
 */
export function switchStatus(id) {
    return post(`/house/housing/admin/switchStatus/${id}`)
}

/**
 * 管理员邀请取消
 */
export function adminInvertDetails(id) {
    return get(`/house/housing/admin/invert/${id}`)
}

/**
 * 管理员邀请取消
 */
export function adminDetails(id) {
    return get(`/house/housing/admin/${id}`, undefined, true)
}

/**
 * 管理员邀请取消
 */
export function adminEdit(id, data) {
    return post(`/house/housing/admin/edit/${id}`, data)
}

/**
 * 账单列表
 */
export function billPageList(data, loading) {
    return post('/house/housing/bill', data, loading)
}


/**
 * 账单列表
 */
export function billItemList(billId) {
    return get(`/house/housing/billItem/${billId}`, undefined, true)
}

/**
 * 账单详情
 */
export function billDetails(id) {
    return get(`/house/housing/bill/${id}`, undefined, true)
}

/**
 * 账单详情
 */
export function billShareDetails(id) {
    return get(`/house/housing/bill/share/${id}`, undefined, true)
}

/**
 * 账单项调价
 */
export function billItemAdjustPrice(data) {
    return post('/house/housing/bill/adjustPrice', data)
}
/**
 * 账单支付成功
 */
export function billPaySuccess(id) {
    return get(`/house/housing/bill/paySuccess/${id}`)
}

/**
 * 费用项创建
 */
export function billCreateItem(data) {
    return post('/house/housing/bill/createItem', data)
}
/**
 *
 * 费用项创建
 */
export function billItem(data) {
    return post('/house/housing/bill/items', data)
}

/**
 * 账单凭证上传
 */
export function billVoucherUpdate(data) {
    return post('/house/housing/bill/voucherUpdate', data, true)
}

/**
 * 账单凭证上传
 */
export function billItemVoucherUpdate(id, data, loading = true) {
    return post(`/house/housing/bill/voucherUpdate/${id}`, data, loading)
}

/**
 * 等待配置上传列表
 */
export function waitVoucherUploadList(buildingId) {
    return get(`/house/housing/bill/voucher/${buildingId}`, null, true)
}

/**
 * 默认配置数据
 */
export function defaultVoucherData(billId) {
    return get(`/house/housing/bill/defaultVoucher/${billId}`, null, true)
}

/**
 * 账单导入模板
 */
export function billImportTemplate(id) {
    return fileDown("GET", `/house/housing/bill/importTemplate/${id}`, null, true, true, "账单模板.xlsx")
}
/**
 * 账单下载
 */
export function billDown(buildingId) {
    return fileDown("GET", `/house/housing/bill/download/${buildingId}`, undefined, true, true, "账单.xlsx")
}

/**
 * 账单导入模板
 */
export function billImport(buildingId, file) {
    return fileUpload(`/house/housing/bill/import/${buildingId}`, file, true)
}

/**
 * 账单导入模板
 */
export function billImportErrorFile(fileName) {
    return fileDown('GET', `/house/housing/bill/import/${fileName}`, null, true, true, "账单倒入模板.xlsx")
}

/************************session***********************/

/**
 * 验证码发送
 * @param object phone
 */
export function registerVerify(phone) {
    return post('/adminSession/auth/sendSms', {phone: phone})
}


/**
 * 手机号+验证码登录接口
 * @param {Object} phone
 * @param {Object} smsCode
 */
export function loginMobile(data) {
    return post('/adminSession/auth/smsLogin', data, true)
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
    return get('/adminSession/auth/getCurrentUser');
}

/**
 * 设置当前用户数据
 */
export function updateCurrentUser(data) {
    data.phone = undefined
    return post('/adminSession/auth/updateCurrentUser', data);
}

/**
 * 创建意见/建议
 */
export function createOpinion(form) {
    return post('/adminSession/auth/createOpinion', form);
}
/**********************./session***********************/

/************************统计***********************/

/**
 * 栋统计
 * @returns {AxiosPromise}
 */
export function suiteAnalysis(shareCode) {
    if(shareCode) {
        return get(`/house/housing/analysis/suite/${shareCode}`);
    } else {
        return get('/house/housing/analysis/suite');
    }
}

/**
 * 账单统计
 * @returns {AxiosPromise}
 */
export function billAnalysis() {
    return get('/house/housing/analysis/bill');
}

/**********************./统计***********************/

/************************配置***********************/

/**
 * 栋配置
 * @returns {AxiosPromise}
 */
export function findConfig() {
    return get('/house/housing/config', undefined, true);
}

/**
 * 账单配置
 * @returns {AxiosPromise}
 */
export function updateConfig(data) {
    return post('/house/housing/config', data, true);
}

/**********************./配置***********************/

/************************通知***********************/

/**
 * 账单统计
 * @returns {AxiosPromise}
 */
export function notifyReadList(params, isLoading) {
    return post('/house/notify/readList', params, isLoading);
}

/**
 * 通知列表
 * @param params
 * @param isLoading
 * @returns {AxiosPromise}
 */
export function notifySendList(params, isLoading) {
    return post('/house/notify/sendList', params, isLoading);
}

/**
 * 通知列表
 * @param params
 * @param isLoading
 * @returns {AxiosPromise}
 */
export function notifyDetailsReadList(params, isLoading) {
    return post('/house/housing/notifyList', params, isLoading);
}

/**
 * 通知发送
 * @param data
 * @returns {AxiosPromise}
 */
export function notifySend(data) {
    return post(`/house/housing/notify`, data, true);
}

/**
 * 通知详情
 * @param id
 * @returns {AxiosPromise}
 */
export function notifyDetails(id) {
    return get(`/house/notify/${id}`, undefined, true);
}

/**
 * 通知详情
 * @param id
 * @returns {AxiosPromise}
 */
export function notifyReadDetails(id) {
    return get(`/house/notify/read/${id}`, undefined, true);
}

/**********************./通知***********************/

/**
 * 协议下载
 */
export function agreementTemplate(suiteId) {
    return fileDown("GET", `/house/housing/bill/agreement/${suiteId}`, undefined, true, true, "协议.pdf")
}
/**
 * 展示创建
 */
export function createDisplayStatus(data) {
    return post('/house/housing/display/create', data, true)
}

/**
 * 展示列表
 */
export function displayPageList(data, isLoading) {
    return post('/house/housing/display/pageList', data, isLoading)
}

/**
 * 展示列表
 */
export function userId2OrganizationId() {
    return get('/house/housing/display/userId2OrganizationId', undefined, false, process.env.VUE_APP_REQUEST_CACHE_EXPIRES_TIME_URGENT)
}
