import store from "@/store";
import Cache from '@/utils/cache';
import {tansParams} from "@/utils/request";

let loginTimer; let loginFlag

const cachePrefix = process.env.VUE_APP_AUTH_CACHE_PREFIX;

function prePage(){
	let pages = getCurrentPages();
	let prePage = pages[pages.length - 1];
	if (prePage.options) {
		return '/' + prePage.route + "?" + tansParams(prePage.options);
	} else {
		return '/' + prePage.route
	}
}

export const toLogin = function () {
	Cache.clear(process.env.VUE_APP_LOGIN_STATUS, cachePrefix)
	throttle(_toLogin,3000)
}

export function _toLogin(pathLogin) {
	let path = prePage();

	path = location.search + (location.hash ? location.hash.replace("#", '') : '');

	if(!pathLogin){
		pathLogin = '/rare/session/login'
		Cache.set(process.env.VUE_APP_BACK_URL,path, 0, cachePrefix);
	}
	uni.navigateTo({
		url: pathLogin
	})
}

export function checkLogin()
{
	let token = Cache.get(process.env.VUE_APP_LOGIN_STATUS, cachePrefix);
	let expiresTime = Cache.get(process.env.VUE_APP_EXPIRES_TIME, cachePrefix);
	let newTime = Math.round(new Date() / 1000);
	if (expiresTime < newTime || !token){
		Cache.clear(process.env.VUE_APP_LOGIN_STATUS, cachePrefix);
		Cache.clear(process.env.VUE_APP_EXPIRES_TIME, cachePrefix);
		Cache.clear(process.env.VUE_APP_USER_INFO, cachePrefix);
		Cache.clear(process.env.VUE_APP_STATE_R_KEY, cachePrefix);
		return false;
	}else{
		store.commit('UPDATE_LOGIN',token);
		let userInfo = Cache.get(process.env.VUE_APP_USER_INFO, cachePrefix);
		if(userInfo){
			store.commit('UPDATE_USERINFO',userInfo);
		}
		return true;
	}
}
export function loginSuccessRequestPage() {
	let backUrl = Cache.get(process.env.VUE_APP_BACK_URL, cachePrefix) || "/pages/home/index";
	if (backUrl.indexOf('/rare/session/login') !== -1) {
		backUrl = '/pages/home/index';
	}
	Cache.clear(process.env.VUE_APP_BACK_URL, cachePrefix)
	return backUrl;
}

/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
export function throttle(func, wait = 500, immediate = true) {
	if (immediate) {
		if (!loginFlag) {
			loginFlag = true
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func()
			loginTimer = setTimeout(() => {
				loginFlag = false
			}, wait)
		}
	} else if (!loginFlag) {
		loginFlag = true
		// 如果是非立即执行，则在wait毫秒内的结束处执行
		loginTimer = setTimeout(() => {
			loginFlag = false
			typeof func === 'function' && func()
		}, wait)
	}
}

