const store = require("../store");

function getShareConfig() {
    let path = "pages/home/index";
    try {
        let userInfo = store.state.app.userInfo;
        if (userInfo && userInfo.ext && userInfo.ext.code) {
            path = `pages/home/index?sCode=${userInfo.ext.code}`;
        }
        console.error("处理后的路径是:", path);
    } catch (e) {
        console.warn("获取用户信息失败:", e);
    }

    return {
        title: '', // 默认为小程序名称
        path: path, // 默认为当前页面路径
        imageUrl: '' // 默认为当前页面的截图
    };
}

module.exports = {
    onShareAppMessage(res) {
        return getShareConfig();
    },
    onShareTimeline(res) {
        return getShareConfig();
    }
}