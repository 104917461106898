import store from '@/store';
import Vue from "vue";
import {def} from "@vue/shared";

export default {
    waitTips: function (delay, opt, to_url) {
        setTimeout(() => {
            this.Tips(opt, to_url);
        }, delay)
    },
    /**
     * opt  object | string
     * to_url object | string
     * 例:
     * this.Tips('/pages/test/test'); 跳转不提示
     * this.Tips({title:'提示'},'/pages/test/test'); 提示并跳转
     * this.Tips({title:'提示'},{tab:1,url:'/pages/index/index'}); 提示并跳转值table上
     * tab=1 一定时间后跳转至 table上
     * tab=2 一定时间后跳转至非 table上
     * tab=3 一定时间后返回上页面
     * tab=4 关闭所有页面跳转至非table上
     * tab=5 关闭当前页面跳转至table上
     */
    Tips: function (opt, to_url) {
        if (typeof opt == 'string') {
            to_url = opt;
            opt = {};
        }
        let title = opt.title || '',
            icon = opt.icon || 'none',
            endtime = opt.endtime || 2000,
            success = opt.success;
        if (title) uni.showToast({
            title: title,
            icon: icon,
            duration: endtime,
            success
        })
        if (to_url != undefined) {
            if (typeof to_url == 'object') {
                let tab = to_url.tab || 1,
                    url = to_url.url || '';
                switch (tab) {
                    case 1:
                        //一定时间后跳转至 table
                        setTimeout(function () {
                            uni.switchTab({
                                url: url
                            })
                        }, endtime);
                        break;
                    case 2:
                        //跳转至非table页面
                        setTimeout(function () {
                            uni.navigateTo({
                                url: url,
                            })
                        }, endtime);
                        break;
                    case 3:
                        //返回上页面
                        setTimeout(function () {






                            history.back();

                        }, endtime);
                        break;
                    case 4:
                        //关闭当前所有页面跳转至非table页面
                        setTimeout(function () {
                            uni.reLaunch({
                                url: url,
                            })
                        }, endtime);
                        break;
                    case 5:
                        //关闭当前页面跳转至非table页面
                        setTimeout(function () {
                            uni.redirectTo({
                                url: url,
                            })
                        }, endtime);
                        break;
                }

            } else if (typeof to_url == 'function') {
                setTimeout(function () {
                    to_url && to_url();
                }, endtime);
            } else {
                //没有提示时跳转不延迟
                setTimeout(function () {
                    uni.navigateTo({
                        url: to_url,
                    })
                }, title ? endtime : 0);
            }
        }
    },
    /**
     * 移除数组中的某个数组并组成新的数组返回
     * @param array array 需要移除的数组
     * @param int index 需要移除的数组的键值
     * @param string | int 值
     * @return array
     *
     */
    ArrayRemove: function (array, index, value) {
        const valueArray = [];
        if (array instanceof Array) {
            for (let i = 0; i < array.length; i++) {
                if (typeof index == 'number' && array[index] != i) {
                    valueArray.push(array[i]);
                } else if (typeof index == 'string' && array[i][index] != value) {
                    valueArray.push(array[i]);
                }
            }
        }
        return valueArray;
    },
    /**
     * 生成海报获取文字
     * @param string text 为传入的文本
     * @param int num 为单行显示的字节长度
     * @return array
     */
    textByteLength: function (text, num) {

        let strLength = 0;
        let rows = 1;
        let str = 0;
        let arr = [];
        for (let j = 0; j < text.length; j++) {
            if (text.charCodeAt(j) > 255) {
                strLength += 2;
                if (strLength > rows * num) {
                    strLength++;
                    arr.push(text.slice(str, j));
                    str = j;
                    rows++;
                }
            } else {
                strLength++;
                if (strLength > rows * num) {
                    arr.push(text.slice(str, j));
                    str = j;
                    rows++;
                }
            }
        }
        arr.push(text.slice(str, text.length));
        return [strLength, arr, rows] //  [处理文字的总字节长度，每行显示内容的数组，行数]
    },

    /**
     * 获取分享海报
     * @param array arr2 海报素材
     * @param string store_name 素材文字
     * @param string price 价格
     * @param string ot_price 原始价格
     * @param function successFn 回调函数
     *
     *
     */
    PosterCanvas: function (arr2, store_name, price, ot_price, successFn) {
        let that = this;
        const ctx = uni.createCanvasContext('firstCanvas');
        ctx.clearRect(0, 0, 0, 0);
        /**
         * 只能获取合法域名下的图片信息,本地调试无法获取
         *
         */
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, 750, 1150);
        uni.getImageInfo({
            src: arr2[0],
            success: function (res) {
                const WIDTH = res.width;
                const HEIGHT = res.height;
                // ctx.drawImage(arr2[0], 0, 0, WIDTH, 1050);
                ctx.drawImage(arr2[1], 0, 0, WIDTH, WIDTH);
                ctx.save();
                let r = 110;
                let d = r * 2;
                let cx = 480;
                let cy = 790;
                ctx.arc(cx + r, cy + r, r, 0, 2 * Math.PI);
                // ctx.clip();
                ctx.drawImage(arr2[2], cx, cy, d, d);
                ctx.restore();
                const CONTENT_ROW_LENGTH = 20;
                let [contentLeng, contentArray, contentRows] = that.textByteLength(store_name,
                    CONTENT_ROW_LENGTH);
                if (contentRows > 2) {
                    contentRows = 2;
                    let textArray = contentArray.slice(0, 2);
                    textArray[textArray.length - 1] += '……';
                    contentArray = textArray;
                }
                ctx.setTextAlign('left');
                ctx.setFontSize(36);
                ctx.setFillStyle('#000');
                // let contentHh = 36 * 1.5;
                let contentHh = 36;
                for (let m = 0; m < contentArray.length; m++) {
                    // ctx.fillText(contentArray[m], 50, 1000 + contentHh * m,750);
                    if (m) {
                        ctx.fillText(contentArray[m], 50, 1000 + contentHh * m + 18, 1100);
                    } else {
                        ctx.fillText(contentArray[m], 50, 1000 + contentHh * m, 1100);
                    }
                }
                ctx.setTextAlign('left')
                ctx.setFontSize(72);
                ctx.setFillStyle('#DA4F2A');
                ctx.fillText('￥' + price, 40, 820 + contentHh);

                ctx.setTextAlign('left')
                ctx.setFontSize(36);
                ctx.setFillStyle('#999');
                ctx.fillText('￥' + ot_price, 50, 876 + contentHh);

                var underline = function (ctx, text, x, y, size, color, thickness, offset) {
                    var width = ctx.measureText(text).width;
                    switch (ctx.textAlign) {
                        case "center":
                            x -= (width / 2);
                            break;
                        case "right":
                            x -= width;
                            break;
                    }

                    y += size + offset;

                    ctx.beginPath();
                    ctx.strokeStyle = color;
                    ctx.lineWidth = thickness;
                    ctx.moveTo(x, y);
                    ctx.lineTo(x + width, y);
                    ctx.stroke();
                }
                underline(ctx, '￥' + ot_price, 55, 865, 36, '#999', 2, 0)
                ctx.setTextAlign('left')
                ctx.setFontSize(28);
                ctx.setFillStyle('#999');
                ctx.fillText('长按或扫描查看', 490, 1030 + contentHh);
                ctx.draw(true, function () {
                    uni.canvasToTempFilePath({
                        canvasId: 'firstCanvas',
                        fileType: 'png',
                        destWidth: WIDTH,
                        destHeight: HEIGHT,
                        success: function (res) {
                            // uni.hideLoading();
                            successFn && successFn(res.tempFilePath);
                        }
                    })
                });
            },
            fail: function (err) {
                Vue.$log.error('失败', err)
                uni.hideLoading();
                that.Tips({
                    title: '无法获取图片信息'
                });
            }
        })
    },
    /**
     * 绘制文字自动换行
     * @param array arr2 海报素材
     * @param Number x , y 绘制的坐标
     * @param Number maxWigth 绘制文字的宽度
     * @param Number lineHeight 行高
     * @param Number maxRowNum 最大行数
     */
    canvasWraptitleText(canvas, text, x, y, maxWidth, lineHeight, maxRowNum) {
        if (typeof text != 'string' || typeof x != 'number' || typeof y != 'number') {
            return;
        }
        // canvas.font = '20px Bold PingFang SC'; //绘制文字的字号和大小
        // 字符分隔为数组
        var arrText = text.split('');
        var line = '';
        var rowNum = 1
        for (var n = 0; n < arrText.length; n++) {
            var testLine = line + arrText[n];
            var metrics = canvas.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                if (rowNum >= maxRowNum) {
                    var arrLine = testLine.split('')
                    arrLine.splice(-9)
                    var newTestLine = arrLine.join("")
                    newTestLine += "..."
                    canvas.fillText(newTestLine, x, y);
                    //如果需要在省略号后面添加其他的东西，就在这个位置写（列如添加扫码查看详情字样）
                    //canvas.fillStyle = '#2259CA';
                    //canvas.fillText('扫码查看详情',x + maxWidth-90, y);
                    return
                }
                canvas.fillText(line, x, y);
                line = arrText[n];
                y += lineHeight;
                rowNum += 1
            } else {
                line = testLine;
            }
        }
        canvas.fillText(line, x, y);
    },
    /**
     * 获取活动分享海报
     * @param array arr2 海报素材
     * @param string storeName 素材文字
     * @param string price 价格
     * @param string people 人数
     * @param string count 剩余人数
     * @param function successFn 回调函数
     */
    activityCanvas: function (arrImages, storeName, price, people, count, num, successFn) {
        let that = this;
        let rain = 2;
        const context = uni.createCanvasContext('activityCanvas');
        context.clearRect(0, 0, 0, 0);
        /**
         * 只能获取合法域名下的图片信息,本地调试无法获取
         *
         */
        context.fillStyle = '#fff';
        context.fillRect(0, 0, 594, 850);
        uni.getImageInfo({
            src: arrImages[0],
            success: function (res) {
                context.drawImage(arrImages[0], 0, 0, 594, 850);
                context.setFontSize(14 * rain);
                context.setFillStyle('#333333');
                that.canvasWraptitleText(context, storeName, 110 * rain, 110 * rain, 230 * rain, 30 * rain, 1)
                context.drawImage(arrImages[2], 68 * rain, 194 * rain, 160 * rain, 160 * rain);
                context.save();

                context.setFontSize(14 * rain);
                context.setFillStyle('#fc4141');
                context.fillText('￥', 157 * rain, 145 * rain);

                context.setFontSize(24 * rain);
                context.setFillStyle('#fc4141');
                context.fillText(price, 170 * rain, 145 * rain);

                context.setFontSize(10 * rain);
                context.setFillStyle('#fff');
                context.fillText(people, 118 * rain, 143 * rain);


                context.setFontSize(12 * rain);
                context.setFillStyle('#666666');
                context.setTextAlign('center');
                context.fillText(count, (167 - num) * rain, 166 * rain);

                that.handleBorderRect(context, 27 * rain, 94 * rain, 75 * rain, 75 * rain, 6 * rain);
                context.clip();
                context.drawImage(arrImages[1], 27 * rain, 94 * rain, 75 * rain, 75 * rain);
                context.draw(true, function () {
                    uni.canvasToTempFilePath({
                        canvasId: 'activityCanvas',
                        fileType: 'png',
                        destWidth: 594,
                        destHeight: 850,
                        success: function (res) {
                            // uni.hideLoading();
                            successFn && successFn(res.tempFilePath);
                        }
                    })
                });

            },
            fail: function (err) {
                Vue.$log.error('失败', err)
                uni.hideLoading();
                that.Tips({
                    title: '无法获取图片信息'
                });
            }
        })
    },

    /**
     * 图片圆角设置
     * @param string x x轴位置
     * @param string y y轴位置
     * @param string w 图片宽
     * @param string y 图片高
     * @param string r 圆角值
     */
    handleBorderRect(ctx, x, y, w, h, r) {
        ctx.beginPath();
        // 左上角
        ctx.arc(x + r, y + r, r, Math.PI, 1.5 * Math.PI);
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.lineTo(x + w, y + r);
        // 右上角
        ctx.arc(x + w - r, y + r, r, 1.5 * Math.PI, 2 * Math.PI);
        ctx.lineTo(x + w, y + h - r);
        ctx.lineTo(x + w - r, y + h);
        // 右下角
        ctx.arc(x + w - r, y + h - r, r, 0, 0.5 * Math.PI);
        ctx.lineTo(x + r, y + h);
        ctx.lineTo(x, y + h - r);
        // 左下角
        ctx.arc(x + r, y + h - r, r, 0.5 * Math.PI, Math.PI);
        ctx.lineTo(x, y + r);
        ctx.lineTo(x + r, y);

        ctx.fill();
        ctx.closePath();
    },

    /*
     * 单图上传
     * @param object opt
     * @param callable successCallback 成功执行方法 data
     * @param callable errorCallback 失败执行方法
     */
    uploadImageOne: function (opt, successCallback, errorCallback) {
        let that = this;
        if (typeof opt === 'string') {
            let url = opt;
            opt = {};
            opt.url = url;
        }
        let count = opt.count || 1,
            sizeType = opt.sizeType || ['compressed'],
            sourceType = opt.sourceType || ['album', 'camera'],
            is_load = opt.is_load || true,
            uploadUrl = opt.url || '',
            inputName = opt.name || 'pics',
            pid = opt.pid,
            model = opt.model;

        uni.chooseImage({
            count: count, //最多可以选择的图片总数
            sizeType: sizeType, // 可以指定是原图还是压缩图，默认二者都有
            sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有
            success: function (res) {
                //启动上传等待中...
                uni.showLoading({
                    title: '图片上传中',
                });
                let urlPath = process.env.VUE_APP_HTTP_ADMIN_URL + '/api/admin/upload/image' + "?model=" + model + "&pid=" + pid
                let localPath = res.tempFilePaths[0];
                uni.uploadFile({
                    url: urlPath,
                    filePath: localPath,
                    name: inputName,

                    header: {



                        [process.env.VUE_APP_TOKENNAME]: store.state.app.token
                    },
                    success: function (res) {
                        uni.hideLoading();
                        if (res.statusCode == 403) {
                            that.Tips({
                                title: res.data
                            });
                        } else {
                            let data = res.data ? JSON.parse(res.data) : {};
                            if (data.code == 200) {
                                data.data.localPath = localPath;
                                successCallback && successCallback(data)
                            } else {
                                errorCallback && errorCallback(data);
                                that.Tips({
                                    title: data.message
                                });
                            }
                        }
                    },
                    fail: function (res) {
                        uni.hideLoading();
                        that.Tips({
                            title: '上传图片失败'
                        });
                    }
                })
                // pathToBase64(res.tempFilePaths[0])
                // 	.then(imgBase64 => {
                // 		Vue.$log.debug(imgBase64);

                // 	})
                // 	.catch(error => {
                // 		Vue.$log.error(error)
                // 	})
            }
        })
    },
    /**
     * 处理服务器扫码带进来的参数
     * @param string param 扫码携带参数
     * @param string k 整体分割符 默认为：&
     * @param string p 单个分隔符 默认为：=
     * @return object
     *
     */















































    /*
     * 合并数组
     */
    SplitArray(list, sp) {
        if (typeof list != 'object') return [];
        if (sp === undefined) sp = [];
        for (var i = 0; i < list.length; i++) {
            sp.push(list[i]);
        }
        return sp;
    },
    trim(str) {
        return String.prototype.trim.call(str);
    },
    $h: {
        //除法函数，用来得到精确的除法结果
        //说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
        //调用：$h.Div(arg1,arg2)
        //返回值：arg1除以arg2的精确结果
        Div: function (arg1, arg2) {
            arg1 = parseFloat(arg1);
            arg2 = parseFloat(arg2);
            var t1 = 0,
                t2 = 0,
                r1, r2;
            try {
                t1 = arg1.toString().split(".")[1].length;
            } catch (e) {
            }
            try {
                t2 = arg2.toString().split(".")[1].length;
            } catch (e) {
            }
            r1 = Number(arg1.toString().replace(".", ""));
            r2 = Number(arg2.toString().replace(".", ""));
            return this.Mul(r1 / r2, Math.pow(10, t2 - t1));
        },
        //加法函数，用来得到精确的加法结果
        //说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
        //调用：$h.Add(arg1,arg2)
        //返回值：arg1加上arg2的精确结果
        Add: function (arg1, arg2) {
            arg2 = parseFloat(arg2);
            var r1, r2, m;
            try {
                r1 = arg1.toString().split(".")[1].length
            } catch (e) {
                r1 = 0
            }
            try {
                r2 = arg2.toString().split(".")[1].length
            } catch (e) {
                r2 = 0
            }
            m = Math.pow(100, Math.max(r1, r2));
            return (this.Mul(arg1, m) + this.Mul(arg2, m)) / m;
        },
        //减法函数，用来得到精确的减法结果
        //说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的减法结果。
        //调用：$h.Sub(arg1,arg2)
        //返回值：arg1减去arg2的精确结果
        Sub: function (arg1, arg2) {
            arg1 = parseFloat(arg1);
            arg2 = parseFloat(arg2);
            var r1, r2, m, n;
            try {
                r1 = arg1.toString().split(".")[1].length
            } catch (e) {
                r1 = 0
            }
            try {
                r2 = arg2.toString().split(".")[1].length
            } catch (e) {
                r2 = 0
            }
            m = Math.pow(10, Math.max(r1, r2));
            //动态控制精度长度
            n = (r1 >= r2) ? r1 : r2;
            return ((this.Mul(arg1, m) - this.Mul(arg2, m)) / m).toFixed(n);
        },
        //乘法函数，用来得到精确的乘法结果
        //说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
        //调用：$h.Mul(arg1,arg2)
        //返回值：arg1乘以arg2的精确结果
        Mul: function (arg1, arg2) {
            arg1 = parseFloat(arg1);
            arg2 = parseFloat(arg2);
            var m = 0,
                s1 = arg1.toString(),
                s2 = arg2.toString();
            try {
                m += s1.split(".")[1].length
            } catch (e) {
            }
            try {
                m += s2.split(".")[1].length
            } catch (e) {
            }
            return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
        },
    },
    // 获取地理位置;
    $L: {
        async getLocation() {








            this.doGetLocation();
        },
        doGetLocation() {
            uni.getLocation({
                success: (res) => {
                    uni.removeStorageSync('CACHE_LONGITUDE');
                    uni.removeStorageSync('CACHE_LATITUDE');
                    uni.setStorageSync('CACHE_LONGITUDE', res.longitude);
                    uni.setStorageSync('CACHE_LATITUDE', res.latitude);
                },
                fail: (err) => {






                    if (err.errMsg.indexOf("auth deny") >= 0) {
                        uni.showToast({
                            title: "访问位置被拒绝"
                        })
                    } else {
                        uni.showToast({
                            title: err.errMsg
                        })
                    }

                }
            })
        },
        getSetting: function () {
            return new Promise((resolve, reject) => {
                uni.getSetting({
                    success: (res) => {
                        if (res.authSetting['scope.userLocation'] === undefined) {
                            resolve(0);
                            return;
                        }
                        if (res.authSetting['scope.userLocation']) {
                            resolve(1);
                        } else {
                            resolve(2);
                        }
                    }
                });
            });
        },
        openSetting: function () {
            uni.openSetting({
                success: (res) => {
                    if (res.authSetting && res.authSetting['scope.userLocation']) {
                        this.doGetLocation();
                    }
                },
                fail: (err) => {
                }
            })
        },
        async checkPermission() {
            let status = permision.isIOS ? await permision.requestIOS('location') :
                await permision.requestAndroid('android.permission.ACCESS_FINE_LOCATION');

            if (status === null || status === 1) {
                status = 1;
            } else if (status === 2) {
                uni.showModal({
                    content: "系统定位已关闭",
                    confirmText: "确定",
                    showCancel: false,
                    success: function (res) {
                    }
                })
            } else if (status.code) {
                uni.showModal({
                    content: status.message
                })
            } else {
                uni.showModal({
                    content: "需要定位权限",
                    confirmText: "设置",
                    success: function (res) {
                        if (res.confirm) {
                            permision.gotoAppSetting();
                        }
                    }
                })
            }
            return status;
        },
    },

    toStringValue: function (obj) {
        if (obj instanceof Array) {
            var arr = [];
            for (var i = 0; i < obj.length; i++) {
                arr[i] = toStringValue(obj[i]);
            }
            return arr;
        } else if (typeof obj == 'object') {
            for (var p in obj) {
                obj[p] = toStringValue(obj[p]);
            }
        } else if (typeof obj == 'number') {
            obj = obj + '';
        }
        return obj;
    },

    /*
     * 替换域名
     */
    setDomain: function (url) {
        url = url ? url.toString() : '';
        if (url.indexOf("https://") > -1) return url;
        else return url.replace('http://', 'https://');
    },

    /**
     * 姓名除了姓显示其他
     */
    formatName: function (str) {
        return str.substr(0, 1) + new Array(str.length).join('*');
    },
    /**
     * 转换金额
     * @param amount 金额
     * @returns {string} 转后后的金额格式
     */
    formatPriceCent: function (amount) {
        if (amount) {
            amount = amount / 100;
            const units = [
                { value: 10000000, label: '亿', decimal: true },
                { value: 1000000, label: '百万' },
                { value: 100000, label: '十万' },
                { value: 10000, label: '万', decimal: true},
                { value: 1000, label: '千' }
            ];
            for (const unit of units) {
                if (unit.decimal) {
                    if (amount >= unit.value && amount % (unit.value / 100) === 0) {
                        return `¥${this.addCommas((amount / unit.value).toFixed(2))}${unit.label}`;
                    }
                } else {
                    if (amount >= unit.value && amount % unit.value=== 0) {
                        return `¥${this.addCommas((amount / unit.value).toFixed(2))}${unit.label}`;
                    }
                }
            }

            return `¥${this.addCommas(amount.toFixed(2))}`;
        }
        return '¥0.00';
    },
    /**
     * 转换金额, 将金额格式化成 4位
     * @param amount 金额
     * @returns {string} 转后后的金额格式
     */
    formatCurrencyAmount: function (amount) {
        if (amount && amount > 0) {
            amount = amount / 100;
            const units = ['', '万', '亿'];
            let unitIndex = 0;
            let result = amount;

            // 判断使用的单位
            while (result >= 10000 && unitIndex < units.length - 1) {
                result /= 10000;
                unitIndex++;
            }

            // 格式化小数部分，最多保留两位
            result = Math.round(result * 100) / 100;

            // 转换为字符串，确保小数点后最多保留两位
            result = result.toFixed(2);

            // 如果小数部分是 .00，则去掉小数点和两个零
            if (result.slice(-3) === '.00') {
                result = result.slice(0, -3);
            }

            // 如果整数部分超过 4 位，则显示成 x.x 万、x.x 亿 等形式
            if (Math.floor(result) >= 10) {
                result = Math.floor(result).toString();
            }
            // 返回最终的结果，加上对应的单位
            return '¥' + result + units[unitIndex];
        }
        return '¥0.00';
    },
    formattedNumber: function (number) {
        if (number) {
            return number.toLocaleString(); // "1,234,567.89"
        }
        return '';
    },
    addCommas(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    /**
     * cdn url地址
     * @param url
     * @param origin
     * @return {*}
     */
    cdnUrl: function (url, origin) {
        if (url && url.indexOf("http") < 0 && url.indexOf("static") < 0) {
            return origin + url;
        }
        return url;
    },
    formatDateMd: function (timestamp) {
        return uni.$u.timeFormat(timestamp, 'mm-dd');
    },
    formatDateM: function (timestamp) {
        return uni.$u.timeFormat(timestamp, 'mm月');
    },
    formatDateYmd: function (timestamp) {
        return uni.$u.timeFormat(timestamp, 'yyyy-mm-dd');
    },
    formatDateYmdHm: function (timestamp) {
        return uni.$u.timeFormat(timestamp, 'yyyy-mm-dd hh:MM');
    },
    /**
     * 中文数字
     * @param value
     * @returns {string}
     */
    chineseNumber: function (value) {
        const chineseDigits = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        const chineseUnits = ['', '十', '百', '千', '万'];

        const numberStr = String(value);
        let result = '';

        for (let i = 0; i < numberStr.length; i++) {
            const digit = parseInt(numberStr[i]);
            result += chineseDigits[digit] + chineseUnits[numberStr.length - i - 1];
        }

        return result;
    },
    /**
     * 判断是否是数字字符串
     * @param str
     * @returns {boolean}
     */
    isNumericString(str) {
        if (str) {
            return /^\d+$/.test(str);
        }
        return false;
    },
    /**
     * 比例高度
     */
    proportionHeight(proportion) {
        return this.getSystemWidth() * proportion;
    },
	/**
	 * 保留两位小数
	 * @param num
	 * @returns {number}
	 */
    keepOneDecimal(num) {
        return Math.round(num * 10) / 10;
    },
	/**
	 * 保留两位小数
	 * @param num
	 * @returns {number}
	 */
    keepTwoDecimal(num) {
        return Math.round(num * 100) / 100;
    },
    getSystemHeight() {
        const res = uni.getSystemInfoSync();
        const screenHeightPx = res.screenHeight; // 屏幕高度，单位px
        const screenWidthPx = res.screenWidth; // 屏幕宽度，单位px
        return (screenHeightPx / screenWidthPx) * 750; // 系统高度
    },
    getSystemWidth() {
        const res = uni.getSystemInfoSync();
        const screenWidthPx = res.screenWidth; // 屏幕宽度，单位px
        return (screenWidthPx / screenWidthPx) * 750; // 系统宽度
    },
    px2rpx(pxValue) {
        let screenWidth = uni.getSystemInfoSync().screenWidth; // 获取屏幕宽度
        return  pxValue * (750 / screenWidth); // px转成rpx
    },
    keyJoin(index, suiteIndex) {
        return `${index}_${suiteIndex}`; // px转成rpx
    },
    getDriver() {
        try {
            const res = uni.getSystemInfoSync();
            // const platform = res.platform;
            const platform = res.uniPlatform;
            switch (platform) {
                case 'ios':
                    return 'Ios';
                case 'android':
                    return 'Android';
                case 'h5':
                    return "H5"
                case 'mp-weixin':
                    return 'WechatMiniApps';
                case 'mp-alipay':
                    return 'Alipay';
                case 'web':
                    return 'WEB';
                case 'devtools':
                    // console.log('当前运行在开发工具上');
                case 'mp-baidu':
                    // console.log('当前运行在百度小程序环境');
                case 'mp-toutiao':
                    // console.log('当前运行在字节跳动小程序环境');
                case 'mp-qq':
                    // console.log('当前运行在QQ小程序环境');
                default:
                    return 'Other';
            }
        } catch (err) {
            console.error('获取系统信息失败：', err);
        }
    },
    isWechatMP() { // 是否是微信
        if (["H5", 'WEB'].includes(this.getDriver())) {
            return /micromessenger/i.test(window.navigator.userAgent);
        }
        return false;
    },
    /**
     * 是否是 tabBar 页面
     * @returns {boolean}
     */
    isTabBarPage(self) {
        // 获取 TabBar 配置页面列表
        const tabBarPages = uni.getApp().$options.tabBar.list.map(item => item.pagePath);
        // 获取当前页面路径
        const currentPage = self.$mp.page.route;  // 当前页面的路径，不包括 .vue 后缀
        // 判断当前页面是否是 TabBar 页面
        return !!tabBarPages.includes(currentPage);
    }
}
